import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import {FontLoader, TextGeometry} from "three/addons";

const fontLoader = new FontLoader();
fontLoader.load('/fonts/helvetiker_regular.typeface.json', (font) => {
    const textGeometry = new TextGeometry(
        "Philip\nDausend",
        {
            font: font,
            size: 0.5,
            depth: 0.2,
            curveSegments: 6,
            bevelEnabled: true,
            bevelThickness: 0.03,
            bevelSize: 0.02,
            bevelOffset: 0,
            bevelSegments: 4
        }
    );
    textGeometry.computeBoundingBox();
    textGeometry.center();

    const material = new THREE.MeshMatcapMaterial({matcap: matcapTexture});
    const text = new THREE.Mesh(textGeometry, material);
    scene.add(text);

    const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45);
    const boxGeometry = new THREE.BoxGeometry(0.75, 0.75);
    const coneGeometry = new THREE.ConeGeometry(0.4, 0.7, 4);
    const octahedronGeometry = new THREE.OctahedronGeometry(0.8);
    for(let i = 0; i < 1000; i++) {
        let geometryToUse;
        const selection = i % 4;
        if (selection === 0) {
            geometryToUse = donutGeometry;
        } else if (selection === 1) {
            geometryToUse = boxGeometry;
        } else if (selection === 2) {
            geometryToUse = coneGeometry;
        } else {
            geometryToUse = octahedronGeometry;
        }

        const mesh = new THREE.Mesh(geometryToUse, material);

        mesh.position.x = (Math.random() - 0.5) * 50;
        mesh.position.y = (Math.random() - 0.5) * 50;
        mesh.position.z = (Math.random() - 0.5) * 50;

        mesh.rotation.x = Math.random() * Math.PI;
        mesh.rotation.y = Math.random() * Math.PI;

        const scale = Math.random();
        mesh.scale.set(scale, scale, scale);

        scene.add(mesh);
    }
})

const canvas = document.querySelector('canvas.webgl');

const scene = new THREE.Scene();

const textureLoader = new THREE.TextureLoader();
const matcapTexture = textureLoader.load('/textures/matcaps/8.png');
matcapTexture.colorSpace = THREE.SRGBColorSpace;

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
camera.position.x = 1;
camera.position.y = 1;
camera.position.z = 2;
camera.lookAt(0,0,0);
scene.add(camera);

const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.enableZoom = false;
controls.autoRotate = true;

const renderer = new THREE.WebGLRenderer({
    canvas: canvas
});

renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const tick = () =>
{
    controls.update()
    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
};

tick();